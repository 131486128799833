export default {
  init() {
    // JavaScript to be fired on all pages
    const videoHero = document.querySelector('.video-hero');
    const header = document.querySelector('header');
    const esg_menu = document.querySelector('.esg-nav');
    //define global variable for vimeo API
    var video_title = '';
    // get the height of the header and set it to the variable headerHeight
    const headerHeight = header.offsetHeight;
      // give videoHero a minheight of 100vh - the header height
    if(videoHero) {
      videoHero.style.minHeight = `calc(100vh - ${headerHeight}px)`;
    }
    // give videoHero a margin-top equal to the header height
    // videoHero.style.marginTop = `${headerHeight}px`;
    if(videoHero && window.location.href.indexOf('esg-framework') > -1) {
      const esg_menu_height = esg_menu.offsetHeight;
      const esg_framework_header_height = headerHeight + esg_menu_height;
      videoHero.style.minHeight = 'calc(100vh - ' + esg_framework_header_height + 'px)';
    }
    if(videoHero && window.location.href.indexOf('esg-framework') > -1 && window.innerWidth < 900) {
      videoHero.style.minHeight = 'unset';
    }

    const peopleItems = document.querySelectorAll('.people-item');
    const peopleItemsThumb = document.querySelectorAll('.people-item > img');
    if(peopleItems) { 
      // loop through each people item and onclick remove the class of "active" from each of them
      peopleItemsThumb.forEach(item => {
        item.addEventListener('click', () => {
          peopleItems.forEach(item => {
            item.classList.remove('active');
          });
          // add the class of "active" to the clicked item
          item.closest('.people-item').classList.add('active');
        });
      });
  
      const modalClose = document.querySelectorAll('.modal-close');
      // when clicked, remove the class of "active" from all the item in peopleItems
      modalClose.forEach(item => {
        item.addEventListener('click', () => {
          peopleItems.forEach(item => {
            item.classList.remove('active');
          }
          );
        }
        );
      }
      )
    }

    // video panel section functionality
    let videoPanels = document.querySelectorAll('.video-panel[data-video], .video-carousel-slide');
    let videoModal = document.querySelector('.video-modal');

    videoPanels.forEach(panel => {
      panel.addEventListener('click', () => {
        let videoModalIframe = document.createElement('iframe');
        videoModalIframe.setAttribute('width', '640');
        videoModalIframe.setAttribute('height', '360');
        videoModalIframe.setAttribute('frameborder', '0');
        videoModalIframe.setAttribute('allow', 'autoplay; fullscreen; picture-in-picture');
        videoModalIframe.setAttribute('allowfullscreen', '');
        videoModalIframe.src = panel.dataset.video;
        // console.log(videoModalIframe);
        videoModal.append(videoModalIframe);
        let player = new Vimeo.Player(videoModalIframe);
        player.on('play', function() {
          //Get title via promise, and add 
          player.getVideoTitle().then(function(title) {
            dataLayer.push({
              'event' : 'video_start',
              'video_provider' : 'vimeo',
              'video_title' : title,
            });
          });
        });
        let watched_10_percent = false;
        let watched_25_percent = false;
        let watched_50_percent = false;
        let watched_75_percent = false;
        let watched_100_percent = false;
        player.on('timeupdate', (data) => {
          // console.log(data.percent);
          if(data.percent > .10 && !watched_10_percent) {
            player.getVideoTitle().then(function(title) {
              dataLayer.push({
                'event' : 'watched_10_percent',
                'video_provider' : 'vimeo',
                'video_title' : title,
              });
            });
            watched_10_percent = true;
          }

          if(data.percent > .25 && !watched_25_percent) {
            player.getVideoTitle().then(function(title) {
              dataLayer.push({
                'event' : 'watched_25_percent',
                'video_provider' : 'vimeo',
                'video_title' : title,
              });
            });
            watched_25_percent = true;
          }

          if(data.percent > .50 && !watched_50_percent) {
            player.getVideoTitle().then(function(title) {
              dataLayer.push({
                'event' : 'watched_50_percent',
                'video_provider' : 'vimeo',
                'video_title' : title,
              });
            });
            watched_50_percent = true;
          }

          if(data.percent > .75 && !watched_75_percent) {
            player.getVideoTitle().then(function(title) {
              dataLayer.push({
                'event' : 'watched_75_percent',
                'video_provider' : 'vimeo',
                'video_title' : title,
              });
            });
            watched_75_percent = true;
          }

          if(data.percent == 1 && !watched_100_percent) {
            player.getVideoTitle().then(function(title) {
              dataLayer.push({
                'event' : 'watched_100_percent',
                'video_provider' : 'vimeo',
                'video_title' : title,
              });
            });
            watched_100_percent = true;
          }
        });
        videoModal.classList.add('open');
      })
    })

    videoModal.addEventListener('click', () => { 
      videoModal.classList.remove('open');
      videoModal.innerHTML = '';
    })

    // add event listener for when the esc key is pressed
    document.addEventListener('keydown', (e) => {
      if(e.keyCode === 27) {
        videoModal.classList.remove('open');
        videoModal.innerHTML = '';
      }
    }
    )
   
    //fire confirmation to leave site
    if(window.location.href.indexOf('santa-fe-foundation') > -1 || window.location.href.indexOf('reynolds-foundation') > -1) {
      const external_links = document.querySelectorAll('a[target="blank"], a[target="_blank"');
      external_links.forEach(function(external_link) {
        external_link.addEventListener('click', function(event){
          event.preventDefault();
          const confirm_message = "Please note that by clicking this link, you will be leaving our website and entering a third-party website over which we have no control.\n\n We are not responsible for the content or availability of linked sites.\n\n Please be aware that the privacy policies of linked sites may differ from our own. We encourage you to read the privacy statements of every website that collects personally identifiable information.\n\n The information you provide on third-party websites is not covered by our privacy policy.\n\n Thank you for visiting our website.";
          if (window.confirm(confirm_message)) {
            // window.location.href = external_link.href;
            window.open(external_link.href, '_blank');
          }
        });
      });
    }

      //mobile trigger
      var mobileTriggerOpen = document.querySelector('.mobile-togle-open');
      var mobileTriggerClose = document.querySelector('.mobile-togle-close');
  
      mobileTriggerOpen.addEventListener('click', function() {
        document.querySelector('header').classList.add('mobile');
        this.classList.add('hide');
        document.querySelector('.mobile-togle-close').classList.add('show');
        $('html, body').addClass('no-scroll');
      })
  
      mobileTriggerClose.addEventListener('click', function() {
        document.querySelector('header').classList.remove('mobile');
        this.classList.remove('show');
        document.querySelector('.mobile-togle-open').classList.remove('hide');
        $('html, body').removeClass('no-scroll');
      })
  
      $('.mobile-menu .nav > li').on('click', function() {
        $(this).toggleClass('open');
        $(this).children('ul').slideToggle();
      })

    //CTA Modals

    const modal_ctas = document.querySelectorAll('.modal-cta');
    modal_ctas.forEach(modal_cta => {
      modal_cta.addEventListener('click', function() {
        let modal_cta_animated_circle = modal_cta.querySelector('.circle-plus');
        modal_cta_animated_circle.classList.toggle('opened');
        let modal_cta_id = modal_cta.dataset.modalId;
        let modal_popup = document.querySelector('dialog[data-modal-id="' + modal_cta_id + '"]');
        // console.log(modal_popup);
        setTimeout(function() {
          modal_popup.showModal();

          let current_modal_slideshow = jQuery(modal_popup).find('.modal-slideshow-container');
          current_modal_slideshow.slick({
            autoplay: true,
            autoplaySpeed: 5000,
            dots: false,
            arrows: true,
            prevArrow: '<img class="prev-arrow" src="/wp-content/themes/Reynolds/dist/images/arrow_left_white_slideshow.png">',
            nextArrow: '<img class="next-arrow" src="/wp-content/themes/Reynolds/dist/images/arrow_right_white_slideshow.png">',
            appendArrows: current_modal_slideshow.siblings('.modal-slide-nav'),
            pauseOnHover: false,
          });
          let animated_dots = modal_popup.querySelectorAll('.dot');
          let animated_lines = modal_popup.querySelectorAll('.line');
          //Bind click event to animated dots to allow them to navigate
          animated_dots.forEach((animated_dot) => {
            animated_dot.addEventListener('click', function() {
              let animated_dot_number = animated_dot.dataset.dotNumber;
              // console.log(animated_dot_number);
              // let current_modal_slideshow = jQuery(modal_popup).find('.modal-slideshow-container');
              current_modal_slideshow.slick('slickGoTo', animated_dot_number);
            });
          });
          current_modal_slideshow.on('beforeChange', function(event, slick, currentSlide, nextSlide){
            animated_dots.forEach((dot) => {
              if(dot.dataset.dotNumber <= nextSlide) {
                if(dot.dataset.dotNumber == nextSlide) {
                  dot.classList.remove('active-animate');
                  setTimeout(function() {
                    dot.classList.add('active-animate');
                  }, 100);
                }
                dot.classList.add('active');
              }
              else {
                dot.classList.remove('active');
                dot.classList.remove('active-animate');
              }
            });

            animated_lines.forEach((line) => {
              if(line.dataset.lineNumber <= nextSlide) {
                if(line.dataset.lineNumber == nextSlide) {
                  line.classList.remove('active');
                }
                setTimeout(function() {
                  line.classList.add('active');
                }, 100);
              }
              else {
                line.classList.remove('active');
              }
            });

          });
        }, 400);
      });
    });

    const modal_close_buttons = document.querySelectorAll('.modal-close-button');
    const animated_circles = document.querySelectorAll('.circle-plus');
    modal_close_buttons.forEach((modal_close_button) => {
      modal_close_button.addEventListener('click', function() {
        animated_circles.forEach((animated_circle) => {
          animated_circle.classList.remove('opened');
        });
        let closest_dialog_box = this.closest('dialog');
        let current_modal_slideshow = jQuery(closest_dialog_box).find('.modal-slideshow-container');
        current_modal_slideshow.slick('unslick');
        closest_dialog_box.close();
      });
    });

    jQuery('.ei-footer-slideshow-container').slick({
      slidesToShow: 1,
      autoplay: true,
      autoplaySpeed: 8000,
      infinite: false,
      dots: false,
      arrows: true,
      prevArrow: '<img class="prev-arrow" src="/wp-content/themes/Reynolds/dist/images/arrow_left_blue.png">',
      nextArrow: '<img class="next-arrow" src="/wp-content/themes/Reynolds/dist/images/arrow_right_blue.png">',
      appendArrows: '.footer-slide-nav',
      appendDots: '.footer-slide-nav',
      pauseOnHover: false,
    });
  
    jQuery('.ei-footer-slideshow-container').on('beforeChange', function(event, slick, currentSlide, nextSlide){
      jQuery('#current-slide-number').text(nextSlide + 1);
    });

    

  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
    
  },
};
