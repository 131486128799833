export default {
  init() {

  },
  finalize() {
    let options = {
      rootMargin: "0px",
      threshold: 0.5,
    };
  
    let callback = (entries, observer) => {
      entries.forEach((entry) => {
        // Each entry describes an intersection change for one observed
        // target element:
        //   entry.boundingClientRect
        //   entry.intersectionRatio
        //   entry.intersectionRect
        //   entry.isIntersecting
        //   entry.rootBounds
        //   entry.target
        //   entry.time
        if(entry.isIntersecting) {
          countUp(entry.target);
        }

        // else {
        //   count_element.textContent = "0";
        // }
      });
    };
  
    let observer = new IntersectionObserver(callback, options);

    const observe_sections = document.querySelectorAll('.count-up');
    observe_sections.forEach(function(observe_section) {
      observer.observe(observe_section);
    });
    
    //Custom slick slider info
  },
};