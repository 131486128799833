// import external dependencies
import 'jquery';

// Import everything from autoload
import './autoload/_bootstrap.js'

// import local dependencies
import Router from './util/Router';
import common from './routes/common';
import home from './routes/home';
import aboutUs from './routes/about';
import esgReports from './routes/esg_reports';
import environment from './routes/environment';
import social from './routes/social';
import governance from './routes/governance';
import goals from './routes/goals';
import diversityInclusion from './routes/diversity_inclusion';
import societalImpact from './routes/societal_impact';
import reducingHarm from './routes/reducing_harm';
import productStewardship from './routes/product_stewardship';

/** Populate Router instance with DOM routes */
const routes = new Router({
  // All pages
  common,
  // Home page
  home,
  // About Us page, note the change from about-us to aboutUs.
  aboutUs,
  //ESG Reports
  esgReports,
  //Environment
  environment,
  //Social
  social,
  //governance
  governance,
  //goals
  goals,
  //Diversity Inclusion
  diversityInclusion,
  // Societal Impact
  societalImpact,
  //Reducing Harm
  reducingHarm,
  //Product Environmental Stewardship
  productStewardship
});

// Load Events
jQuery(document).ready(() => routes.loadEvents());
